<template>
  <div class="w-full">
    <div class="w-full">
      <div class="pc_show">
        <div>
          <div class="flex items-center justify-start mt-4 drive_title_box">
            <img src="../../../../assets/images/serve/warranty_icon.png" alt />
            <div class="items-center drive_title">保修查询</div>
          </div>
          <div class="mt-4 text-left text-gray-500 path">
            <navbar-text :navbarList="navbarList"></navbar-text>
          </div>
        </div>
      </div>
      <div class="_d">
        <div>
          <div class="flex items-center justify-start mt-4 drive_title_box">
            <img src="../../../../assets/images/serve/warranty_icon.png" alt />
            <div class="items-center drive_title">保修查询</div>
          </div>
          <div class="text-left text-gray-500 path">
            <navbar-text :navbarList="navbarList"></navbar-text>
          </div>
          <!-- <navbar-text :navbarList="navbarList"></navbar-text> -->
        </div>
      </div>
      <div class="flex items-center justify-between w-full my-2 mt-4 tab">
        <div class="flex items-center">
          <div v-for="(item, index) in tabs" :key="index" class="pr-4 duration-500 cursor-pointer"
            :class="tabIndex == index ? 'text-primary' : ''" @click="changeTabIndex(index)">
            {{ item }}
          </div>
        </div>
        <div class="flex items-center">
          <div class="px-2 py-1 text-white rounded-sm cursor-pointer bg-primary" @click="goPage">
            寄修申请
          </div>
        </div>
      </div>
      <div style="overflow-x: scroll;width:100%;">
        <div class="table w-full">
          <div class="flex items-center duration-500 table_title">
            <div v-for="(item, index) in tableTitle" :key="index" class="flex items-center duration-500"
              style="width: 20%;min-width: 6.25rem;" :class="
                index == 0
                  ? 'justify-start'
                  : index == 4
                    ? 'justify-end'
                    : 'justify-center'
              ">
              {{ item }}
            </div>
          </div>
          <div v-if="list.length > 0" class="">
            <ul class="w-full">
              <li class="flex items-center w-full duration-500" v-for="(item, index) in list" :key="index">
                <div class="flex items-center justify-start duration-500 li_item" style="width: 20%;min-width: 6.25rem;">
                  {{ item.serviceOrderNumber }}
                </div>
                <div class="flex items-center justify-center duration-500 li_item product"
                  style="width: 20%;min-width: 6.25rem;">
                  {{ item.modelName }}
                </div>
                <div class="flex items-center justify-center duration-500 li_item desc"
                  style="width: 20%;min-width: 6.25rem;">
                  {{ item.createTime }}
                </div>
                <div class="flex items-center justify-center duration-500 li_item" style="width: 20%;min-width: 6.25rem;"
                  :class="tabIndex == 0 ? 'justify-end' : 'justify-center'">
                  <!-- {{
                    item.status == 1
                      ? "申请中"
                      : item.status == 2
                        ? "处理中"
                        : "申请中"
                  }} -->
                  {{ statusObj[item.status] }}
                </div>
                <div class="flex items-center justify-end duration-500 cursor-pointer li_item operation"
                  style="width: 20%;min-width: 6.25rem;">
                  <span class="text-primary" @click="modifyFunc(item)"
                    v-if="item.status == 1 || item.status == 3">[修改]</span>
                  <span class="ml-2 text-gray-400" @click="goDetail(item)">[详情]</span>
                </div>
              </li>
            </ul>
          </div>
          <div v-else class="flex items-center justify-center py-2 bg-white null_box">
            <div class="text-gray-400">暂无数据</div>
          </div>
        </div>
      </div>
    </div>
    <Popup :isShow="isShow" :width="popWidth" @close="closeSearch">
      <template>
        <div class="p-2">
          <div class="pb-4 text-base font-bold sm:text-xl">申请进度</div>
          <div class="text-left">{{ refuseContent }}</div>
          <div class="py-2" v-if="applyStatus == 2 && tabIndex == 1">
            <el-input type="text" v-model="shipmentNumber" placeholder="您的申请已通过，请输入快递单号"></el-input>
            <el-button style="margin-top: 10px;width:50%;" size="small" type="primary"
              @click="warrantyOrder">提交</el-button>
          </div>
          <div class="py-2" v-if="applyStatus == 6">
            <el-button style="margin-top: 10px;width:50%;" size="small" type="primary"
              @click="userReceipt">确认收货</el-button>
          </div>
        </div>
      </template>
    </Popup>

    <Popup :isShow="showInfo" @close="closePopup" :width="isMobile ? '90vw' : popupWidth">
      <template>
        <div class="relative px-4">
          <div class="absolute top-0 right-0 cursor-pointer" @click="closePopup">
            <!-- <img src="../../../../assets/images/common/close.png" alt="" style="width: 30px;" /> -->
          </div>
          <div class="py-3 text-center text-gray-700 md:text-xl">工单异常提醒</div>
          <div class="my-3 text-sm text-center text-gray-500">
            您的工单出现异常，请在个人中心维修查询中查看您提交的工单，尽快填写物流单号，不然会影响您电脑的维修进度。
          </div>
          <div class="flex items-center justify-center pb-2">
            <div class="py-1 text-white cursor-pointer bg-primary serve_btn" @click="goViewAnomaly">
              立即处理
            </div>
          </div>
        </div>
      </template>
    </Popup>

  </div>
</template>

<script>
import Popup from "@/components/Popup/Popup";
import { mapGetters, mapState } from "vuex";
import { orderStatusMap } from "@/utils/utils.js";

export default {
  data() {
    return {
      tabs: ["全部订单", "申请中", "处理中", "已完成"],
      tabIndex: 0,
      tableTitle: ["订单编号", "型号", "提交时间", "状态", "操作"],
      // applyTitle: ['订单编号', '产品', '描述', '下单时间'],
      // dealTitle: ['服务单号', '订单编号', '商品名称|机型名称', '申请时间', '状态'],
      // phoneTitle: ['订单编号', '产品', '描述', '下单时间'],
      list: [],
      statusObj: orderStatusMap,
      refuseContent: "",
      isShow: false,
      popWidth: "90%",
      screenWidth: "",
      shipmentNumber: "",
      applyStatus: "", // 申请状态
      warrantyId: "", // 保修单Id
      pcShow: true,
      navbarList: [
        { title: "个人中心", path: "/index/personal/message" },
        { title: "保修查询", path: "" },
      ],
      showInfo: false,
      popupWidth: "25rem",
      anomalyWarrantyId: "",//异常工单号
    };
  },
  components: {
    Popup,
  },
  computed: {
    ...mapState(["isMobile"]),
    ...mapGetters(["getUserInfo", "getLoginStatus"]),
  },
  created() {
    if (this.getLoginStatus) {
      // this.$store.dispatch("aquireUserInfo", this.$EventBus);
    } else {
      this.$EventBus.$emit("toast", { type: "error", message: "请先登录" });
      setTimeout(() => {
        this.$router.push({ path: "/index" });
      }, 1000);
    }
    this.getWarranty();
    this.screenWidth = window.innerWidth;
    this.setSize();
    // 窗口大小发生改变时,调用一次
    window.onresize = () => {
      this.screenWidth = window.innerWidth;
      this.setSize();
    };

    // 获取七牛token
    this.$store.dispatch("getAccessToken", this.$EventBus);
  },
  watch: {
    tabIndex: function () {
      this.getWarranty();
      // if(this.screenWidth < 680){

      // }
    },
  },
  methods: {
    closePopup() {
      this.showInfo = false
    },
    // 跳转到修改页面
    modifyFunc(item) {
      this.$router.push({
        path: "/index/warranty/apply?warrantyId=" + item.warrantyId,
      });
    },
    // 跳转到详情
    goDetail(item) {
      this.$router.push({
        path: "/index/sales/orderDetail?warrantyId=" + item.warrantyId,
      });
    },
    /**
     * @description: 查看异常工单
     * @return {*}
     */
    goViewAnomaly() {
      this.$router.push({
        path: "/index/sales/orderDetail?warrantyId=" + this.anomalyWarrantyId,
      });
    },
    setSize() {
      // 通过浏览器宽度(图片宽度)计算高度
      if (this.screenWidth < 680) {
        this.popWidth = "90vw";
        this.pcShow = false;
      } else {
        this.popWidth = "25rem";
        this.pcShow = true;
      }
    },
    changeTabIndex(index) {
      if (this.tabIndex == index) {
        return;
      }
      this.tabIndex = index;
    },
    goPage() {
      this.$router.push({ path: "/index/warranty/apply" });
    },
    // 查看处理反馈
    showProcessContent(item) {
      console.log(item);
      // console.log('getUserInfo', this.getUserInfo)
      this.isShow = true;
      this.applyStatus = item.status;
      this.warrantyId = item.warrantyId;
      switch (item.status) {
        case 1:
          this.refuseContent = item.processContent
            ? item.processContent
            : "设备正在申请中";
          break;
        case 2:
          this.refuseContent = "申请已通过，请输入快递单号";
          break;
        case 3:
          this.refuseContent = item.processContent
            ? item.processContent
            : "设备申请被拒绝，请重新申请";
          break;
        case 4:
          this.refuseContent = "设备正在运输中";
          break;
        case 5:
          this.refuseContent = "设备正在维修中";
          break;
        case 6:
          this.refuseContent = "设备维修已完成，请确认收货";
          break;
        case 7:
          this.refuseContent = "用户已确认";
          break;
      }
    },
    // 获取保修数据
    getWarranty() {
      this.$request({
        method: "get",
        url:
          this.tabIndex == 0
            ? this.$requestPath.equipment
            : `${this.$requestPath.equipment}?status=${this.tabIndex}`,
        data: {},
      })
        .then((res) => {
          if (res.code == 0) {
            res.data.forEach((item) => {
              item.date = "";
              item.createTime
                ? (item.date = item.createTime.split(" ")[0])
                : "";
            });
            let index = res.data.findIndex(item => {
              return item.latestTime && new Date().getTime() > new Date().getTime(item.latestTime)
            })
            if (index > -1) {
              this.anomalyWarrantyId = res.data[index].warrantyId
              this.showInfo = true
            }
            this.list = [];
            this.list.push(...res.data);
          } else {
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("获取用户设备", error);
        });
    },
    // 关闭弹窗
    closeSearch() {
      this.isShow = false;
    },
    // 提交快递单号
    warrantyOrder() {
      let data = {
        shipmentNumber: this.shipmentNumber,
        userId: this.getUserInfo.userId,
        warrantyId: this.warrantyId,
      };
      // console.log("data", data)
      // return
      this.$request({
        method: "post",
        url: this.$requestPath.warrantyOrder,
        data,
      })
        .then((res) => {
          console.log("订单提交", res);
          if (res.code == 0) {
            this.$EventBus.$emit("toast", {
              type: "success",
              message: "单号提交成功",
            });
            this.isShow = false;
            this.getWarranty();
          } else {
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("获取用户设备", error);
        });
    },
    // 用户确认收货
    userReceipt() {
      let data = {
        shipmentNumber: this.shipmentNumber,
        userId: this.getUserInfo.userId,
        warrantyId: this.warrantyId,
      };
      // console.log("data", data)
      // return
      this.$request({
        method: "post",
        url: this.$requestPath.userReceipt,
        data,
      })
        .then((res) => {
          console.log("确认收货", res);
          if (res.code == 0) {
            this.$EventBus.$emit("toast", {
              type: "success",
              message: "操作成功",
            });
            this.isShow = false;
            this.getWarranty();
          } else {
            this.$EventBus.$emit("toast", { type: "error", message: res.msg });
          }
        })
        .catch((error) => {
          console.log("用户确认收货", error);
        });
    },
    goCheck() {
      this.$router.push({ path: "/index/inspection" });
    },
  },
};
</script>

<style lang="scss" scoped>
.table_title {
  background: #e8e8e8;
}

.li_item {
  word-wrap: break-word;
  word-break: break-all;
}

.serve_btn {
  width: 80%;
}

@media screen and (max-width: 640px) {
  .pc_d_show {
    display: block;
  }

  .phone_d_show {
    display: none;
  }

  .pc_show {
    display: none;
  }

  ._d {
    display: flex;
  }

  .drive_title_box {
    margin: 0.625rem 0px;

    img {
      width: 1.4375rem;
    }

    .drive_title {
      font-size: 1rem;
      margin-left: 0.375rem;
      display: flex;
      padding-top: 0.0625rem;
    }
  }

  .table {
    margin-top: 0.625rem;

    .table_title {
      height: 2.1875rem;
      font-size: 0.8125rem;
      padding: 0px 0.625rem;
    }

    ul {
      li {
        // min-height: 1.875rem;
        padding: 0.3125rem 0.625rem;
        font-size: 0.75rem;
      }

      li {
        border: 0.0625rem solid #ccc;
        border-top: none;
      }

      li:nth-child(1) {
        border-top: 0.0625rem solid #ccc;
      }

      // .product {
      //     width: 15%;
      // }
      // .desc {
      //     width: 15%;
      // }
      // .operation {
      //     width: 15%;
      // }
    }
  }
}

@media screen and (min-width: 640px) {

  .pc_d_show {
    display: none;
  }

  .phone_d_show {
    display: block;
  }

  .pc_show {
    display: flex;
  }

  ._d {
    display: none;
  }

  .drive_title_box {

    // margin: 0.625rem 0px;
    img {
      width: 1.25rem;
    }

    .drive_title {
      font-size: 0.9375rem;
      margin-left: 0.375rem;
      display: flex;
      padding-top: 0.0625rem;
    }
  }

  .table {
    margin-top: 0.625rem;

    .table_title {
      height: 2.5rem;
      padding: 0px 0.625rem;
    }

    ul {
      li {
        height: 2.375rem;
        padding: 0px 0.625rem;
        font-size: 0.875rem;
      }

      li {
        border: 0.0625rem solid #ccc;
        border-top: none;
      }

      li:nth-child(1) {
        border-top: 0.0625rem solid #ccc;
      }
    }
  }
}
</style>
